
import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { VscChromeClose } from "react-icons/vsc";
import "../assests/css/cookies.scss";

function Cookies() {
  const [cookiesAccepted, setCookiesAccepted] = useState(localStorage.getItem('cookiesAccepted'));

  useEffect(() => {
    const checkCookies = () => {
      if (!cookiesAccepted) {
        setTimeout(() => {
          const cookiesContainer = document.getElementById('cookiesContainer');
          cookiesContainer.classList.add('show');
        }, 2000); // Delay of 4 seconds (4000 milliseconds)
      }
    };

    checkCookies();
  }, [cookiesAccepted]);

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setCookiesAccepted(true);
    var cookiesContainer = document.getElementById('cookiesContainer');
    cookiesContainer.style.display = 'none';
  };

  const rejectCookies = () => {
    localStorage.setItem('cookiesAccepted', 'false');
    setCookiesAccepted(false); 
    var cookiesContainer = document.getElementById('cookiesContainer');
    cookiesContainer.style.display = 'none'; // Hide the cookies message after rejecting
  };

  return (
    <div className={`cookies-container`} id="cookiesContainer">
      <div className="container">
        <div className="row">
          <div className="col-11 order1">
            <p className="cookies-message">At Haantlay, we use cookies to enhance your experience. By continuing to browse our website, you consent to our use of cookies.</p>
          </div>
          <div className="col-1 cbtn order2">        
            <button className="cookies-btn3" onClick={acceptCookies}><VscChromeClose /></button>
            {/* <button className="cookies-btn2" onClick={acceptCookies}>I Accept</button>
            <button className="cookies-btn1" onClick={rejectCookies}>Reject All</button> */}
          </div>
        </div>     
      </div>
    </div>
  );
}

export default Cookies;
