import React from 'react';

const Preloader = () => {
  return (
    <div id="preloader-div">
      <h2 className="preloader-text">
        HAANTLAY
      </h2>
    </div>
  );
};

export default Preloader;
